import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { UserInfo } from "../../../context";

const ProductForm = ({ getProductList, close, merchant_id, categoryList }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);
  const [image, setImage] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);

  const handleNewProduct = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.append("merchant_id", merchant_id);
    formdata.append("image", image[0]);
    var err = [];
    if (image.length < 1) {
      err = "error";
      setOpenSnack(true);
    }
    if (err.length > 0) {
      console.log("form has a problem!");
    } else {
      setIsProcess(true);
      close();
      axios
        .post("merchant/create/new-product", formdata)
        .then((response) => {
          const data = response.data;
          if (data === "success") {
            setMessage("Successfully added product");
            setSeverity("success");
            setTimeout(() => {
              setIsProcess(false);
              setOpenSnackbar(true);
              setTimeout(() => {
                getProductList();
              }, 500);
            }, 1000);
          }
        })
        .catch((error) => {
          setIsProcess(false);
          console.log("error", error);
        });
    }
  };

  const handleImageChangeNew = (files) => {
    setImage(files);
  };

  return (
    <>
      <Box sx={{ m: 2 }}>
        <form onSubmit={handleNewProduct} encType="multipart/form-data">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontWeight: 700 }}>New Product</Typography>
          </Box>

          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Name"
              fullWidth
              size="small"
              name="product"
              required
              multiline
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Description"
              fullWidth
              size="small"
              name="description"
              //   required
              multiline
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              select
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Category"
              fullWidth
              size="small"
              name="category"
              required
              multiline
            >
              <option value=""> - Select category - </option>
              {categoryList.data.map((data, index) => (
                <option key={index} value={data.id}>
                  {data.category}
                </option>
              ))}
            </TextField>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  min: 1,
                  max: 99999999999,
                  step: 0.01,
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Price"
              fullWidth
              size="small"
              name="price"
              required
              type="number"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // mb: 1,
            }}
          >
            <TextField
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#000",
                  // fontFamily: "Futura Md Bt",
                  fontWeight: 700,
                },
                "& .MuiInput-input": {
                  borderRadius: "8px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                  fontSize: 14,
                },
                "& label + .MuiInput-root-emKjRF": {
                  marginTop: "18px",
                },
              }}
              InputProps={{
                disableunderline: "true",
                inputProps: {
                  style: {
                    color: "#AEAEAE",
                    backgroundColor: "#fff",
                  },
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              label="Product Unit"
              fullWidth
              size="small"
              name="unit"
              required
              multiline
            />
          </Box>

          {/* {console.log("variant", variantList)} */}

          {/* <Box sx={{ display: "flex", alignItems: "center", paddingX: 2 }}>
            <Box flexGrow={1}>
              <Typography sx={{ fontWeight: "bold" }}>
                Variant
              </Typography>
            </Box>
            <Box>
              {isMobile ? (
                <Tooltip title="Add Variant">
                  <IconButton
                    onClick={() => {
                      var existing = [];
                      let variants = { variant: "", variant_price: "" };
                      console.log("existing", existing)
                      setVariantList(existing.push(variants));
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>

              ) : (
                <Tooltip title="Add Variant">
                  <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="text"
                    onClick={() => {
                      var existing = [];
                      let variants = { variant: "", variant_price: "" };
                      console.log("existing", existing)
                      setVariantList(existing.push(variants));
                    }}
                    sx={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    Add
                  </Button>
                </Tooltip>
              )}
            </Box>
          </Box> */}

          <Box sx={{ display: "flex", alignItems: "center", paddingX: 2, mb: 2, }}>
            <Grid container spacing={1}>
              {/* {variantList.map((data, index) => (
                <Fragment key={index}>
                  <Grid item xs={6}>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          // fontFamily: "Futura Md Bt",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Name"
                      fullWidth
                      size="small"
                      name="description"
                      //   required
                      multiline
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000",
                          // fontFamily: "Futura Md Bt",
                          fontWeight: 700,
                        },
                        "& .MuiInput-input": {
                          borderRadius: "8px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                          paddingRight: "14px",
                          fontSize: 14,
                        },
                        "& label + .MuiInput-root-emKjRF": {
                          marginTop: "18px",
                        },
                      }}
                      InputProps={{
                        disableunderline: "true",
                        inputProps: {
                          min: 1,
                          max: 99999999999,
                          step: 0.01,
                          style: {
                            color: "#AEAEAE",
                            backgroundColor: "#fff",
                          },
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Price"
                      fullWidth
                      size="small"
                      name="variant_price"
                      required
                      type="number"
                    />
                  </Grid>
                </Fragment>
              ))} */}
            </Grid>
          </Box>

          <Box sx={{ mb: 2 }}>
            <DropzoneArea
              onChange={handleImageChangeNew}
              acceptedFiles={["image/jpeg", "image/png"]}
              dropzoneText={"Upload product image"}
              maxFileSize={2000000}
              filesLimit={1}
              dropzoneClass={'dropZoneCustom'}
              dropzoneParagraphClass={'dropZoneCustomText'}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box flexGrow={1} />
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: 2,
                  fontSize: 12,
                  paddingX: 2,
                  backgroundColor: "#261138",
                }}
                type="submit"
                disabled={isProcess}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpenSnack(false);
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenSnack(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Image of the product is required
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductForm;
