import { Add, Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    Divider,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import axios from 'axios';
import React from 'react';
import { UserInfo } from '../../context';
import { useEffect } from 'react';
import { useState } from 'react';
import { DropzoneArea } from 'react-mui-dropzone';
import moment from 'moment';

const FoodBanner = () => {
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = React.useContext(UserInfo);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [merchantBanner, setMerchantBanner] = useState({ data: [], ready: false })
    const [addFoodBannerDialog, setAddFoodBannerDialog] = React.useState(false);
    const [image, setImage] = useState([]);

    const getFoodBanner = () => {
        var params = { category: "food", device: "mobile" }
        axios.get("admin/get/banner", { params })
            .then(response => {
                const data = response.data;
                setMerchantBanner({ data, ready: true })
            })
            .catch(error => console.log("Error: ", error))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleImageChangeNew = (files) => {
        setImage(files);
    };

    const handleNewFoodBanner = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("image", image[0]);
        formdata.append("category", 'food');
        formdata.append("device", 'mobile');

        var err = [];
        if (image.length < 1) {
            err = "error";
            setOpenSnackbar(true);
        }
        if (err.length > 0) {
            console.log("form has a problem!");
        } else {
            setIsProcess(true);
            setAddFoodBannerDialog(false);
            axios
                .post("admin/create/new-food-banner", formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === "success") {
                        setMessage("Successfully added food banner");
                        setSeverity("success");
                        setTimeout(() => {
                            setIsProcess(false);
                            setOpenSnackbar(true);
                            setTimeout(() => {
                                getFoodBanner();
                            }, 500);
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setIsProcess(false);
                    // console.log("error", error);
                });
        }
    };

    useEffect(() => {
        getFoodBanner()
    }, [])

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex" }}>
                <Box flexGrow={1} />
                <Box>
                    <Tooltip title="Filter">
                        <IconButton
                            onClick={() => {
                                setAddFoodBannerDialog(true);
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align='center'><b>Date</b></TableCell>
                            <TableCell align='center'><b>Header</b></TableCell>
                            <TableCell align='center'><b>Sub Header</b></TableCell>
                            <TableCell align='center'><b>Status</b></TableCell>
                            {/* <TableCell align='center'><b>Action</b></TableCell> */}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {merchantBanner.ready ?
                            merchantBanner.data.length > 0 ?
                                merchantBanner.data.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{moment(data.created_at).format('MMMM DD, YYYY')}</TableCell>
                                        <TableCell>{data.header}</TableCell>
                                        <TableCell>{data.subheader}</TableCell>
                                        <TableCell>{parseFloat(data.status) === 1 ? "Active" : 'Deactive'}</TableCell>
                                        {/* <TableCell align='center'>
                                            <Tooltip title="Complete">
                                                <IconButton
                                                // onClick={() => handleUpdateConfirm(data.code, 'complete')}
                                                >
                                                    <DoneAllIcon sx={{ color: '#e5e5e5' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell> */}
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell align='center' colSpan={7} style={{ color: "red" }}>No food banner found!</TableCell>
                                    </TableRow>
                                )
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={merchantBanner.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={addFoodBannerDialog}
                onClose={() => setAddFoodBannerDialog(false)}
                maxWidth="xs"
                fullWidth
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
                        <Box flexGrow={1}>
                            <Typography style={{ fontWeight: 700 }}>Add Food Banner</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => setAddFoodBannerDialog(false)}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <form
                            onSubmit={handleNewFoodBanner}
                        >
                            <Box sx={{
                                mt: 2,
                                mb: 2,
                            }}>
                                <DropzoneArea
                                    onChange={handleImageChangeNew}
                                    acceptedFiles={["image/jpeg", "image/png"]}
                                    dropzoneText={"Upload banner"}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    dropzoneClass={'dropZoneCustom'}
                                    dropzoneParagraphClass={'dropZoneCustomText'}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    InputProps={{
                                        disableunderline: "true",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Header"
                                    fullWidth
                                    name="header"
                                    required
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    InputProps={{
                                        disableunderline: "true",
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Sub Header"
                                    fullWidth
                                    name="subheader"
                                    required
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Box flexGrow={1} />
                                <Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            paddingX: 2,
                                            backgroundColor: "#d65d46",
                                        }}
                                        type="submit"
                                        disabled={isProcess}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box >
            </Dialog >
        </Box >
    )
}

export default FoodBanner;