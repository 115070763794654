import { Box, Button, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { QRCode } from 'react-qrcode-logo';

const GenerateQRWLogo = () => {
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedUrl, setSelectedUrl] = useState('')

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const uploadImage = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setSelectedImage(base64)
        // avatar.src = base64;
        // textArea.innerText = base64;
    };

    const downloadCode = () => {
        const canvas = document.getElementById("QR");
        // canvas.setAttribute('width', '2000px');
        // canvas.setAttribute('height', '2000px');

        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.alt = 'NehemiahQR';
            downloadLink.href = pngUrl;
            downloadLink.download = `qr.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            // canvas.setAttribute('width', '600px');
            // canvas.setAttribute('height', '600px');
        }


    };

    return (
        <>
            <Box
                sx={{
                    width: "100vw",
                    height: "calc(100vh - 64px)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Box>
                        <QRCode
                            value={selectedUrl}
                            size={600}
                            logoImage={selectedImage}
                            logoHeight={150}
                            logoWidth={150}
                            logoOpacity={1}
                            enableCORS={true}
                            // qrStyle="dots"
                            eyeRadius={1}
                            id={"QR"}
                        />
                    </Box>

                    <Box style={{ marginBottom: 20, width: 600 }}>
                        <input
                            type="file"
                            name={`image`}
                            id={'image1'}
                            required={true}
                            onChange={uploadImage}
                        />
                    </Box>
                    <Box style={{ marginBottom: 10, width: 600 }}>
                        <TextField
                            label="Enter URL"
                            fullWidth
                            name="url"
                            required
                            size="small"
                            onChange={(e) => setSelectedUrl(e.target.value)}
                            value={selectedUrl}
                            multiline
                        />
                    </Box>

                    <Box style={{ width: 600 }}>
                        <Button
                            onClick={downloadCode}
                            fullWidth
                            variant="contained"
                        >
                            Download QR
                        </Button>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default GenerateQRWLogo;
