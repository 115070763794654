import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../../layouts/Container";
import { MerchantInfo, UserInfo } from "../../context";

const MerchantUpgrade = () => {
    const { merchantInfo } = useContext(MerchantInfo);
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const url = window.location.search.toString();
    const merchantId = new URLSearchParams(url).get("merchant_id");
    const navigate = useNavigate()

    const handleUpgradeAccount = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("merchant_id", merchantId)
        formdata.append("old_firstname", merchantInfo.data && merchantInfo.data.reg_firstname)
        formdata.append("old_lastname", merchantInfo.data && merchantInfo.data.reg_lastname)
        formdata.append("old_contact_no", merchantInfo.data && merchantInfo.data.reg_contact_no)
        formdata.append("old_email", merchantInfo.data && merchantInfo.data.reg_email)
        formdata.append("old_fullname", `${merchantInfo.data && merchantInfo.data.reg_firstname} ${merchantInfo.data && merchantInfo.data.reg_lastname}`)

        setIsProcess(true);
        // setAddAdminDialog(false);
        axios
            .post("merchant/update/upgrade-to-proprietor", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setMessage("Successfully upgrade the merchant, You will automatically logout.");
                    setSeverity("success");
                    setTimeout(() => {
                        setIsProcess(false);
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            // getAdminList();
                            localStorage.removeItem("estherToken");
                            setTimeout(() => {
                                navigate("/");
                                navigate(0);
                            }, 2000);
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                // console.log("error", error);
            });
    };

    return (
        <Container>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "1200px",
                        maxWidth: "1200px",
                        paddingX: "8px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            paddingY: "30px",
                            maxWidth: "500px",
                            width: "100%",
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <form
                                onSubmit={handleUpgradeAccount}
                            >
                                <Box
                                    sx={{
                                        mt: 5,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mb: 2,
                                        width: "100%",
                                    }}
                                >
                                    <Typography sx={{ fontWeight: 700 }}>Set Manager Account</Typography>
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <Divider light />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mb: 2,
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                color: "#000",
                                                // fontFamily: "Futura Md Bt",
                                                fontWeight: 700,
                                            },
                                            "& .MuiInput-input": {
                                                borderRadius: "8px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                paddingLeft: "14px",
                                                paddingRight: "14px",
                                                fontSize: 14,
                                            },
                                            "& label + .MuiInput-root-emKjRF": {
                                                marginTop: "18px",
                                            },
                                        }}
                                        InputProps={{
                                            disableunderline: "true",
                                            inputProps: {
                                                style: {
                                                    color: "#AEAEAE",
                                                    backgroundColor: "#fff",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Manager Email"
                                        fullWidth
                                        size="small"
                                        name="email"
                                        required
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mb: 2,
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                color: "#000",
                                                // fontFamily: "Futura Md Bt",
                                                fontWeight: 700,
                                            },
                                            "& .MuiInput-input": {
                                                borderRadius: "8px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                paddingLeft: "14px",
                                                paddingRight: "14px",
                                                fontSize: 14,
                                            },
                                            "& label + .MuiInput-root-emKjRF": {
                                                marginTop: "18px",
                                            },
                                        }}
                                        InputProps={{
                                            disableunderline: "true",
                                            inputProps: {
                                                style: {
                                                    color: "#AEAEAE",
                                                    backgroundColor: "#fff",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Manager Firstname"
                                        fullWidth
                                        size="small"
                                        name="firstname"
                                        required
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mb: 2,
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                color: "#000",
                                                // fontFamily: "Futura Md Bt",
                                                fontWeight: 700,
                                            },
                                            "& .MuiInput-input": {
                                                borderRadius: "8px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                paddingLeft: "14px",
                                                paddingRight: "14px",
                                                fontSize: 14,
                                            },
                                            "& label + .MuiInput-root-emKjRF": {
                                                marginTop: "18px",
                                            },
                                        }}
                                        InputProps={{
                                            disableunderline: "true",
                                            inputProps: {
                                                style: {
                                                    color: "#AEAEAE",
                                                    backgroundColor: "#fff",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Manager Lastname"
                                        fullWidth
                                        size="small"
                                        name="lastname"
                                        required
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mb: 2,
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                color: "#000",
                                                // fontFamily: "Futura Md Bt",
                                                fontWeight: 700,
                                            },
                                            "& .MuiInput-input": {
                                                borderRadius: "8px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                paddingLeft: "14px",
                                                paddingRight: "14px",
                                                fontSize: 14,
                                            },
                                            "& label + .MuiInput-root-emKjRF": {
                                                marginTop: "18px",
                                            },
                                        }}
                                        InputProps={{
                                            disableunderline: "true",
                                            inputProps: {
                                                style: {
                                                    color: "#AEAEAE",
                                                    backgroundColor: "#fff",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Manager Password"
                                        fullWidth
                                        size="small"
                                        name="password"
                                        required
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mb: 2,
                                    }}
                                >
                                    <TextField
                                        sx={{
                                            "& .MuiInputLabel-root": {
                                                color: "#000",
                                                // fontFamily: "Futura Md Bt",
                                                fontWeight: 700,
                                            },
                                            "& .MuiInput-input": {
                                                borderRadius: "8px",
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                paddingLeft: "14px",
                                                paddingRight: "14px",
                                                fontSize: 14,
                                            },
                                            "& label + .MuiInput-root-emKjRF": {
                                                marginTop: "18px",
                                            },
                                        }}
                                        InputProps={{
                                            disableunderline: "true",
                                            inputProps: {
                                                style: {
                                                    color: "#AEAEAE",
                                                    backgroundColor: "#fff",
                                                },
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Mobile Number"
                                        fullWidth
                                        size="small"
                                        name="contact_no"
                                        required
                                    />
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                    }}
                                >
                                    <Box flexGrow={1} />
                                    <Box>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                textTransform: "capitalize",
                                                borderRadius: 2,
                                                fontSize: 12,
                                                paddingX: 2,
                                                backgroundColor: "#261138",
                                            }}
                                            type="submit"
                                            disabled={isProcess || (merchantInfo.data && merchantInfo.data.merchantinfoproprietorbranches !== null)}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Container>
    );
};

export default MerchantUpgrade;
