import { Box, Typography } from "@mui/material";
import React from "react";

const GuestPage = () => {
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "calc(100vh - 64px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography style={{ fontWeight: "bold" }} variant="h5">
            Content Coming Soon!
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default GuestPage;
