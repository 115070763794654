import { Close, Verified } from '@mui/icons-material';
import {
    // Badge,
    Box, Button, CardMedia, Dialog, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography
} from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { UserInfo } from '../../context';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import emailjs from '@emailjs/browser';
// import { Info } from '@mui/icons-material';
import ImageViewer from "react-simple-image-viewer";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

const RiderList = ({ allowAccount, module }) => {
    const [userList, setUserList] = useState({ data: [], ready: false })
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [verifyDialog, setVerifyDialog] = useState({ open: false, data: null })
    const [detailsDialog, setDetailsDialog] = useState({ open: false, data: null })
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedData, setSelectedData] = useState({ open: false, data: null })

    const form = useRef();
    const {
        setIsProcess,
        isProcess,
        setMessage,
        setSeverity,
        setOpenSnackbar,
    } = useContext(UserInfo);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getRiderList = () => {
        var params = { module: "rider" }
        axios.get("admin/get/users", { params })
            .then(response => {
                const data = response.data;
                setUserList({ data, ready: true })
            })
            .catch(error => console.log("Error: ", error))
    }

    const handleVerifyAccount = () => {
        var formdata = new FormData()
        formdata.append("id", verifyDialog.data && verifyDialog.data.id)
        setIsProcess(true);
        axios
            .post("admin/update/account-status", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setTimeout(() => {
                        setIsProcess(false);
                        setMessage("Successfully verify the account.");
                        setSeverity("success");
                        setVerifyDialog({ ...verifyDialog, open: false });
                        getRiderList()
                        setTimeout(() => {
                            setOpenSnackbar(true);
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    }

    const handleSendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_r5wcw1v', 'template_n30wg5m', form.current, 'BCGRtcKyS6DJyXOqp')
            .then((result) => {
                if (result.text === 'OK') {
                    setIsProcess(false);
                    setMessage("Successfully send link to the account.");
                    setSeverity("success");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                    }, 500);
                }
            }, (error) => {
                console.log(error.text);
            });
    }

    useEffect(() => {
        getRiderList()
    }, [])

    return (
        <Box sx={{ mt: 2 }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align='center'><b>Name</b></TableCell>
                            <TableCell align='center'><b>Email</b></TableCell>
                            <TableCell align='center'><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList.ready && userList.data.length > 0 ?
                            userList.data.map((data, index) => (
                                <TableRow key={index}>
                                    <TableCell align='center'>
                                        {/* {index + 1} */}
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {parseInt(data.userriderinfo.status) === 1 ? (
                                                <Tooltip title="Online">
                                                    <ToggleOnIcon sx={{ color: 'green' }} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title="Offline">
                                                    <ToggleOffIcon sx={{ color: '#AEAEAE' }} />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <span style={{ cursor: "pointer", color: '#54B4D3' }} onClick={() => setSelectedData({ ...selectedData, open: true, data })}>
                                            {data.userriderinfo.firstname} {data.userriderinfo.lastname}
                                        </span>
                                    </TableCell>
                                    <TableCell>{data.email}</TableCell>
                                    <TableCell align="center">
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            {
                                                parseInt(data.status) === 1 ? (
                                                    <Tooltip title="Verified">
                                                        <Verified sx={{ color: 'green' }} />
                                                    </Tooltip>
                                                ) : (
                                                    <>
                                                        <Tooltip title="Verify Account">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setVerifyDialog({ open: true, data })
                                                                }}
                                                                // disabled={parseInt(data.status) === 1 ? true : false}
                                                                disabled={module === 'admin' && parseInt(allowAccount.allow) === 0 ? true : false}
                                                            >
                                                                <Verified />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title="Send link to Email">
                                                            <form ref={form} onSubmit={handleSendEmail}>
                                                                <input style={{ display: "none" }} readOnly type="text" name="user_name" value={`${data.reg_firstname} ${data.reg_lastname}`} />
                                                                <input style={{ display: "none" }} readOnly type="email" name="user_email" value={data.email} />
                                                                <input style={{ display: "none" }} readOnly type="text" name="from_name" value={`Esther Customer Service`} />
                                                                <textarea style={{ display: "none" }} readOnly name="message" value={`${process.env.REACT_APP_BASEURL}?link=/rider/confirm-documents?id=${data.id}`} />
                                                                <IconButton
                                                                    type='submit'
                                                                    // disabled={parseInt(data.status) === 1 ? true : false}
                                                                    disabled={module === 'admin' && parseInt(allowAccount.allow) === 0 ? true : false}
                                                                >
                                                                    <ForwardToInboxIcon />
                                                                </IconButton>
                                                            </form>
                                                        </Tooltip>
                                                    </>
                                                )
                                            }


                                            {/* <Tooltip title="Attachments">
                                                <IconButton
                                                    onClick={() => {
                                                        setDetailsDialog({ open: true, data })
                                                    }}
                                                >
                                                    {data.userriderinfo.or_cr !== null || data.userriderinfo.driver_license !== null || data.userriderinfo.unit !== null ? (
                                                        <Badge variant="dot" color="error">
                                                            <Info sx={{ color: '#54B4D3' }} />
                                                        </Badge>
                                                    ) : (
                                                        <Info sx={{ color: '#54B4D3' }} />
                                                    )}

                                                </IconButton>
                                            </Tooltip> */}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                            : (
                                <TableRow>
                                    <TableCell align='center' colSpan={6} style={{ color: "red" }}>No rider found</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={userList.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={verifyDialog.open}
                fullWidth
                maxWidth="xs"
                onClose={() => setVerifyDialog({ ...verifyDialog, open: false })}
            >
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography>
                                    Are you sure to verify this account with a name of {verifyDialog.data && `${verifyDialog.data.firstname} ${verifyDialog.data.lastname}`}?
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                <Box flexGrow={1} />
                                <Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            backgroundColor: "#d65d46",
                                            width: "40%",
                                        }}
                                        onClick={handleVerifyAccount}
                                        disabled={isProcess}
                                    >
                                        Confirm
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            backgroundColor: "#AEAEAE",
                                            width: "40%",
                                            ml: 2
                                        }}
                                        onClick={() => setVerifyDialog({ ...verifyDialog, open: false })}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>

            {/* <Dialog
                open={detailsDialog.open}
                fullWidth
                maxWidth="xs"
                onClose={() => setDetailsDialog({ ...detailsDialog, open: false, data: null })}

            >
                {detailsDialog.data !== null && (
                    <Box sx={{ p: 3, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Box>
                            <Typography variant='h6'>
                                {detailsDialog.data.name} attachments
                            </Typography>
                        </Box>
                        {detailsDialog.data.userriderinfo.or_cr !== null && (
                            <>
                                <Box sx={{ mt: 3 }}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_IMAGE}rider/orcrs/${detailsDialog.data.userriderinfo.or_cr}`}
                                        height="100"
                                        alt=""
                                        sx={{ cursor: "pointer", width: 100 }}
                                        onClick={() => {
                                            setSelectedImages(`${process.env.REACT_APP_API_IMAGE}rider/orcrs/` + detailsDialog.data.userriderinfo.or_cr);
                                            setIsViewerOpen(true);
                                            setDetailsDialog({ ...detailsDialog, open: false })
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        {detailsDialog.data.userriderinfo.or_cr}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {detailsDialog.data.userriderinfo.driver_license !== null && (
                            <>
                                <Box sx={{ mt: 3 }}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_IMAGE}rider/driverslicenses/${detailsDialog.data.userriderinfo.driver_license}`}
                                        height="100"
                                        alt=""
                                        sx={{ cursor: "pointer", width: 100 }}
                                        onClick={() => {
                                            setSelectedImages(`${process.env.REACT_APP_API_IMAGE}rider/driverslicenses/${detailsDialog.data.userriderinfo.driver_license}`);
                                            setIsViewerOpen(true);
                                            setDetailsDialog({ ...detailsDialog, open: false })
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        {detailsDialog.data.userriderinfo.driver_license}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {detailsDialog.data.userriderinfo.unit !== null && (
                            <>
                                <Box sx={{ mt: 3 }}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_IMAGE}rider/units/${detailsDialog.data.userriderinfo.unit}`}
                                        height="100"
                                        alt=""
                                        sx={{ cursor: "pointer", width: 100 }}
                                        onClick={() => {
                                            setSelectedImages(`${process.env.REACT_APP_API_IMAGE}rider/units/${detailsDialog.data.userriderinfo.unit}`);
                                            setIsViewerOpen(true);
                                            setDetailsDialog({ ...detailsDialog, open: false })
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        {detailsDialog.data.userriderinfo.unit}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                )}
            </Dialog> */}

            <Dialog
                open={selectedData.open}
                fullWidth
                maxWidth="sm"
                onClose={() => setSelectedData({ ...selectedData, open: false, data: null })}
            >
                {selectedData.data !== null && (
                    <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box flexGrow={1}>
                                <Typography variant='h6'>
                                    {selectedData.data.name} Information
                                </Typography>
                            </Box>
                            <IconButton onClick={() => setSelectedData({ ...detailsDialog, open: false, data: null })}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Email"
                                            fullWidth
                                            size="small"
                                            name="email"
                                            value={selectedData.data.email !== null ? selectedData.data.email : ""}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Firstname"
                                            fullWidth
                                            size="small"
                                            name="firstname"
                                            value={selectedData.data.userriderinfo.firstname !== null ? selectedData.data.userriderinfo.firstname : ""}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Lastname"
                                            fullWidth
                                            size="small"
                                            name="lastname"
                                            value={selectedData.data.userriderinfo.lastname !== null ? selectedData.data.userriderinfo.lastname : ""}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Contact No."
                                            fullWidth
                                            size="small"
                                            name="contact_no"
                                            value={selectedData.data.userriderinfo.contact_no !== null ? selectedData.data.userriderinfo.contact_no : ""}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Address"
                                            fullWidth
                                            size="small"
                                            name="address"
                                            value={selectedData.data.userriderinfo.address !== null ? selectedData.data.userriderinfo.address : ""}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Typography variant='h6'>
                                {selectedData.data.name} Attachments
                            </Typography>
                        </Box>

                        {selectedData.data.userriderinfo.or_cr !== null && (
                            <>
                                <Box sx={{ mt: 3 }}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_IMAGE}rider/orcrs/${selectedData.data.userriderinfo.or_cr}`}
                                        height="100"
                                        alt=""
                                        sx={{ cursor: "pointer", width: 100 }}
                                        onClick={() => {
                                            setSelectedImages(`${process.env.REACT_APP_API_IMAGE}rider/orcrs/` + selectedData.data.userriderinfo.or_cr);
                                            setIsViewerOpen(true);
                                            setDetailsDialog({ ...selectedData, open: false })
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        {selectedData.data.userriderinfo.or_cr}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {selectedData.data.userriderinfo.driver_license !== null && (
                            <>
                                <Box sx={{ mt: 3 }}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_IMAGE}rider/driverslicenses/${selectedData.data.userriderinfo.driver_license}`}
                                        height="100"
                                        alt=""
                                        sx={{ cursor: "pointer", width: 100 }}
                                        onClick={() => {
                                            setSelectedImages(`${process.env.REACT_APP_API_IMAGE}rider/driverslicenses/${selectedData.data.userriderinfo.driver_license}`);
                                            setIsViewerOpen(true);
                                            setDetailsDialog({ ...selectedData, open: false })
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        {selectedData.data.userriderinfo.driver_license}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {selectedData.data.userriderinfo.unit !== null && (
                            <>
                                <Box sx={{ mt: 3 }}>
                                    <CardMedia
                                        component="img"
                                        image={`${process.env.REACT_APP_API_IMAGE}rider/units/${selectedData.data.userriderinfo.unit}`}
                                        height="100"
                                        alt=""
                                        sx={{ cursor: "pointer", width: 100 }}
                                        onClick={() => {
                                            setSelectedImages(`${process.env.REACT_APP_API_IMAGE}rider/units/${selectedData.data.userriderinfo.unit}`);
                                            setIsViewerOpen(true);
                                            setDetailsDialog({ ...selectedData, open: false })
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography variant='caption'>
                                        {selectedData.data.userriderinfo.unit}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                )}
            </Dialog>

            {isViewerOpen && (
                <ImageViewer
                    src={[selectedImages]}
                    currentIndex={0}
                    onClose={() => {
                        setIsViewerOpen(false);
                        setDetailsDialog({ ...detailsDialog, open: true })
                    }}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </Box>
    )
}

export default RiderList;