import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import RiderList from './RiderList';

const RidersAbout = ({ userRestriction, module }) => {
    const [tabPanel, setTabPanel] = React.useState("list");

    const handleChangeMethod = (event, newValue) => {
        setTabPanel(newValue);
    };

    const allowAccount = userRestriction.find(x => parseInt(x.restriction_id) === 1);

    return (
        <>
            <TabContext value={tabPanel}>
                <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Box>
                        <TabList
                            onChange={handleChangeMethod}
                            aria-label="tabs"
                            TabIndicatorProps={{ style: { background: "#1AE294" } }}
                        >
                            <Tab
                                className="customtabbutton"
                                label="Rider List"
                                value="list"
                            />
                        </TabList>
                    </Box>

                    <TabPanel value="list" className="tabpanelzeropadding">
                        <RiderList allowAccount={allowAccount} module={module} />
                    </TabPanel>
                </Box>
            </TabContext>
        </>
    )
}

export default RidersAbout;