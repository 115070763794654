import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { UserInfo } from "./components/context";
import AllRoute from "./routes/AllRoute";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";

const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

axios.defaults.baseURL = process.env.REACT_APP_API;

function MyApp() {
  const estherToken = localStorage.getItem("estherToken");
  const [loginUrl, setLoginUrl] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isProcess, setIsProcess] = React.useState(false);

  const [userData, setUserData] = React.useState({
    data: [],
    authenticated: false,
    redirect: null,
    ready: false,
  });

  const getGoogleURL = () => {
    fetch(`${process.env.REACT_APP_API}google/auth`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => {
        // console.log("URL: ", data.url)
        setLoginUrl(data.url)
      })
      .catch((error) => console.error(error));
  };

  const getTokenAndAccess = () => {
    fetch(`${process.env.REACT_APP_API}user`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + estherToken,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.message === "Unauthenticated.") {
          setUserData({
            data: [],
            authenticated: false,
            ready: true,
          });
        } else {
          setUserData({
            data,
            authenticated: true,
            ready: true,
          });
        }
      });
  };

  React.useEffect(() => {
    getGoogleURL();
    getTokenAndAccess();
    // eslint-disable-next-line
  }, [estherToken]);
  return (
    <>
      <UserInfo.Provider
        value={{
          userData,
          setUserData,
          loginUrl,
          handleRerunUser: () => getTokenAndAccess(),
          message,
          setMessage,
          severity,
          setSeverity,
          isProcess,
          setIsProcess,
          openSnackbar,
          setOpenSnackbar,
        }}
      >
        <BrowserRouter>
          <AllRoute />
        </BrowserRouter>
      </UserInfo.Provider>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenSnackbar(false);
          }}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProcess}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" size={70} />
          <Typography sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      </Backdrop>
    </>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <MyApp />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </LocalizationProvider>
    </>
  );
}
