import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { UserInfo } from "../../context";
import { Button, Dialog, Hidden, IconButton, InputAdornment, TablePagination, TextField, Tooltip } from "@mui/material";
import OrderDetails from "../order-details";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Close, Search } from "@mui/icons-material";
import TodayIcon from '@mui/icons-material/Today';
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { isMobile } from "react-device-detect";
import InfoIcon from '@mui/icons-material/Info';

const MerchantIncome = () => {
    const { userData, setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = React.useContext(UserInfo);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const [viewOrder, setViewOrder] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [openFilter, setOpenFilter] = React.useState(false);
    const [dateFrom, setDateFrom] = React.useState(new Date());
    const [dateTo, setDateTo] = React.useState(new Date());
    const [merchantProducts, setMerchantProducts] = React.useState({
        data: [],
        ready: false,
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let totalTrnx = 0;

    const searchable = merchantProducts.data.filter((data) => {
        return data.transactionID.replace("t-", "").toLowerCase().indexOf(search.trim()) !== -1;
    });

    const calculate = (amount) => {
        totalTrnx = parseFloat(totalTrnx) + parseFloat(amount)
        return amount;
    }

    const handleFilter = () => {
        var params = { user_id: userData.data.id, date_from: dateFrom, date_to: dateTo }
        var error = [];
        if (dateFrom > dateTo) {
            error = 'error'
            setIsProcess(false);
            setMessage("Invalid inputed dates.");
            setSeverity("error");
            setTimeout(() => {
                setOpenSnackbar(true);
            }, 500);
        }
        if (error.length > 0) {
            console.log("Something went wrong.")
        } else {
            setIsProcess(true);
            axios.get("merchant/get/orders-filter", { params })
                .then(response => {
                    const data = response.data;
                    setOpenFilter(false)
                    setIsProcess(false);
                    setMessage("Successfully filter record.");
                    setSeverity("success");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                        setMerchantProducts({ data, ready: true });
                    }, 500);
                })
                .catch(error => {
                    setIsProcess(false);
                    console.log("Error: ", error)
                })
        }
    }

    const getMerchantOrdersFromCustomer = () => {
        var params = { user_id: userData.data.id, merchant_status: 'complete' };
        axios
            .get("merchant/get/orders", { params })
            .then((response) => {
                const data = response.data;
                setMerchantProducts({ data, ready: true });
            })
            .catch((err) => console.log("err", err));
    };

    React.useEffect(() => {
        getMerchantOrdersFromCustomer()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Box
                sx={{
                    width: "100vw",
                    // maxWidth: "1165px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 2,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            maxWidth: "1165px",
                            mx: "15px",
                        }}
                    >
                        <Box>
                            <Typography variant="h5">Transactions</Typography>
                        </Box>
                        <Box flexGrow={1} />
                        <Hidden smDown>
                            <Box
                                sx={{ mr: 1 }}
                            >
                                <TextField
                                    label="Search Collection ID"
                                    variant="outlined"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    size="small"
                                />
                            </Box>
                        </Hidden>
                        <Box>
                            <Tooltip title="Filter">
                                <IconButton
                                    onClick={() => {
                                        setOpenFilter(true);
                                    }}
                                >
                                    <TodayIcon />
                                </IconButton>
                            </Tooltip>

                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // justifyContent: "center",
                        paddingX: "15px"
                    }}
                >
                    {
                        merchantProducts.data.length > 0 && (
                            searchable.map((data, index) => (
                                <Box
                                    sx={{
                                        borderTop: "1px solid #AEAEAE",
                                        borderLeft: "1px solid #AEAEAE",
                                        borderBottom: "1px solid #AEAEAE",
                                        borderRight: "4px solid green",
                                        borderRadius: 2,
                                        width: "100%",
                                        maxWidth: "1165px",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: 1,
                                        mb: 1
                                    }}
                                    key={index}
                                >
                                    <Box sx={{ mr: 2 }}>
                                        <TaskAltIcon sx={{ fontSize: "30px", color: "#50C878" }} />
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography
                                            gutterBottom
                                            variant="subtitle1"
                                            sx={{ fontWeight: 700 }}
                                        >
                                            {data.transactionID.replace("t-", "")}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="caption"
                                        >
                                            {moment(data.created_at).format('MMMM DD, YYYY')}
                                        </Typography>
                                    </Box>
                                    <Box flexGrow={1} />
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: 170 }}>
                                        <Typography
                                            gutterBottom
                                            variant="caption"
                                        >
                                            Cost: &#8369;{`${parseFloat(data.total_trnx).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                                        </Typography>
                                        <span hidden>{calculate(data.total_trnx)}</span>
                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: isMobile ? 30 : 120 }}>
                                        {isMobile ? (
                                            <IconButton
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: 12,
                                                    color: "#50C878",
                                                    alignItems: "center",
                                                    padding: 1
                                                }}
                                                onClick={() => {
                                                    setSelectedOrder(data);
                                                    setViewOrder(true);
                                                }}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                        ) : (
                                            <Typography
                                                sx={{
                                                    color: "#fff",
                                                    fontWeight: 700,
                                                    fontSize: 12,
                                                    backgroundColor: "#50C878",
                                                    borderRadius: 1,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    padding: 1
                                                }}
                                                onClick={() => {
                                                    setSelectedOrder(data);
                                                    setViewOrder(true);
                                                }}
                                            >
                                                ORDER DETAILS
                                            </Typography>
                                        )}

                                    </Box>
                                </Box>
                            ))
                        )
                    }
                    <Box sx={{
                        display: "flex",
                        width: "100%",
                        maxWidth: "1165px",
                    }}>
                        <Box flexGrow={1} />
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 25]}
                            component="div"
                            count={searchable.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mb: 2,
                        mt: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            maxWidth: "1165px",
                            mx: "15px",
                        }}
                    >
                        {/* <Box flexGrow={1}>{merchantProducts.data.length} record(s) found</Box> */}
                        <Box flexGrow={1} />
                        <Box>
                            Total: &#8369;{`${parseFloat(totalTrnx).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                        </Box>
                    </Box>
                </Box>

                <Dialog
                    open={viewOrder}
                    onClose={() => setViewOrder(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <OrderDetails
                        selectedOrder={selectedOrder}
                        close={() => {
                            setViewOrder(false);
                        }}
                        route="income"
                    />
                </Dialog>

                <Dialog
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    maxWidth="xs"
                    fullWidth
                >
                    <Box sx={{ padding: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box flexGrow={1}>
                                <Typography style={{ fontWeight: 700 }}>Filter Record</Typography>
                            </Box>
                            <Box>
                                <IconButton onClick={() => setOpenFilter(false)}>
                                    <Close fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <DatePicker
                                // disableFuture
                                label="Date From"
                                openTo="year"
                                views={['year', 'month', 'day']}
                                value={dateFrom}
                                onChange={(newValue) => {
                                    setDateFrom(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <DatePicker
                                // disableFuture
                                label="Date To"
                                openTo="year"
                                views={['year', 'month', 'day']}
                                value={dateTo}
                                onChange={(newValue) => {
                                    setDateTo(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Box>

                        <Box sx={{ display: "flex", mt: 2 }}>
                            <Box flexGrow={1} />
                            <Box>
                                <Button
                                    variant="contained"
                                    size="small"
                                    sx={{
                                        backgroundColor: "#1976d2",
                                    }}
                                    onClick={handleFilter}
                                    disabled={isProcess}
                                >
                                    Filter
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Dialog>
            </Box>
        </>
    );
};
export default MerchantIncome;
