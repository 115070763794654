import React, { useContext } from "react";
import { UserInfo } from "../components/context";
import AdminRoute from "./AdminRoute";
import GuestRoute from "./GuestRoute";
import RiderRoute from "./RiderRoute";
import CustomerRoute from "./CustomerRoute";
import MerchantRoute from "./MerchantRoute";
import ProprietorRoute from "./ProprietorRoute";

function AllRoute() {
  const { userData } = useContext(UserInfo);

  return (
    <>
      {userData.ready ? (
        userData.authenticated === true && userData.data.module === "admin" ? (
          <AdminRoute module={userData.data.module} />
        )
          : userData.authenticated === true && userData.data.module === "superadmin" ? (
            <AdminRoute module={userData.data.module} />
          )
            : userData.authenticated === true && userData.data.module === "merchant" ? (
              <MerchantRoute module={userData.data.module} />
            )
              : userData.authenticated === true && userData.data.module === "customer" ? (
                <CustomerRoute module={userData.data.module} />
              )
                : userData.authenticated === true && userData.data.module === "rider" ? (
                  <RiderRoute module={userData.data.module} />
                )
                  : userData.authenticated === true && userData.data.module === "proprietor" ? (
                    <ProprietorRoute module={userData.data.module} />
                  )
                    : (
                      <GuestRoute />
                    )
      ) : null}
    </>
  );
}

export default AllRoute;
