import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { UserInfo } from '../../context';

const AdminProfile = () => {
    const { userData, handleRerunUser, setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo)
    const [validEmail, setValidEmail] = useState(true)

    const handleUpdate = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        formdata.set("id", userData.data.id);
        formdata.set("old_email", userData.data.email);
        setIsProcess(true);

        if (!validEmail) {
            setIsProcess(false);
            setMessage("Invalid new email.");
            setSeverity("error");
            setTimeout(() => {
                setOpenSnackbar(true);
            }, 500);
        } else {
            axios
                .post("admin/update/info", formdata)
                .then((response) => {
                    const data = response.data;
                    if (data === "success") {
                        setIsProcess(false);
                        setMessage("Successfully update your info.");
                        setSeverity("success");
                        handleRerunUser()
                        setTimeout(() => {
                            setOpenSnackbar(true);
                        }, 500);
                    }
                    if (data === "already-exist") {
                        setIsProcess(false);
                        setMessage("New email is already taken.");
                        setSeverity("error");
                        setTimeout(() => {
                            setOpenSnackbar(true);
                        }, 500);
                    }
                })
                .catch((error) => {
                    setIsProcess(false);
                    console.log("error", error);
                });
        }



        // var error = [];

        // if (!validEmail) {
        //     error = 'error';

        //     console.log("YAWA")
        //     console.log("validEmail", validEmail)
        // }

        // if (error.length > 0) {
        //     error = 'error';

        //     console.log("PISTE")
        //     console.log("validEmail", validEmail)

        // } else {
        //     axios
        //         .post("admin/update/info", formdata)
        //         .then((response) => {
        //             const data = response.data;
        //             if (data === "success") {
        //                 setIsProcess(false);
        //                 setMessage("Successfully update your info.");
        //                 setSeverity("success");
        //                 setTimeout(() => {
        //                     setOpenSnackbar(true);
        //                 }, 500);
        //             }
        //             if (data === "already-exist") {
        //                 setIsProcess(false);
        //                 setMessage("New email is already taken.");
        //                 setSeverity("error");
        //                 setTimeout(() => {
        //                     setOpenSnackbar(true);
        //                 }, 500);
        //             }
        //         })
        //         .catch((error) => {
        //             setIsProcess(false);
        //             console.log("error", error);
        //         });
        // }


    };

    const handleUpdatePassword = (e) => {
        e.preventDefault();
        e.persist();

        var formdata = new FormData(e.target);
        formdata.set("user_id", userData.data.id);
        formdata.set("email", userData.data.email);
        setIsProcess(true);
        axios
            .post("update/password", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setIsProcess(false);
                    setMessage("Successfully update password.");
                    setSeverity("success");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                    }, 500);
                } else {
                    setIsProcess(false);
                    setMessage("Invalid current password.");
                    setSeverity("error");
                    setTimeout(() => {
                        setOpenSnackbar(true);
                    }, 500);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    return (
        <Box
            style={{
                // border: "1px solid red",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: isMobile ? "start" : "center",
                height: 'calc(100vh - 96px)',
                width: "100%"
            }}
        >

            {/* <Box>
                <Typography style={{ fontWeight: "bold" }} variant="h5">
                    PROFILE
                </Typography>
            </Box> */}
            <Box style={{ maxWidth: '600px' }}>
                <form onSubmit={handleUpdate}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: 700 }}> My Profile</Typography>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <Divider light />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Email Address"
                            fullWidth
                            size="small"
                            name="email"
                            defaultValue={userData.data.email !== null ? userData.data.email : ""}
                            required
                            autoComplete='off'
                            onChange={(e) => {
                                var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                                if (e.target.value.match(mailformat)) {
                                    setValidEmail(true);
                                } else {
                                    setValidEmail(false);
                                }
                            }}
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Fullname"
                            fullWidth
                            size="small"
                            name="name"
                            defaultValue={userData.data.name !== null ? userData.data.name : ""}
                            required
                            onChange={() => {

                            }}
                            autoComplete='off'
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Box flexGrow={1} />
                        <Box>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: 2,
                                    fontSize: 12,
                                    paddingX: 2,
                                    backgroundColor: "#d65d46",
                                }}
                                type="submit"
                                disabled={isProcess}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
                <form
                    onSubmit={handleUpdatePassword}
                >
                    <Box
                        sx={{
                            mt: 5,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: 700 }}>My Credentials</Typography>
                    </Box>
                    <Box sx={{ mb: 3 }}>
                        <Divider light />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Current pasword"
                            fullWidth
                            size="small"
                            name="password"
                            defaultValue={"password"}
                            type={"password"}
                            required
                            autoComplete='off'
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                "& .MuiInputLabel-root": {
                                    color: "#000",
                                    // fontFamily: "Futura Md Bt",
                                    fontWeight: 700,
                                },
                                "& .MuiInput-input": {
                                    borderRadius: "8px",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    fontSize: 14,
                                },
                                "& label + .MuiInput-root-emKjRF": {
                                    marginTop: "18px",
                                },
                            }}
                            InputProps={{
                                disableunderline: "true",
                                inputProps: {
                                    style: {
                                        color: "#AEAEAE",
                                        backgroundColor: "#fff",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="New password"
                            fullWidth
                            size="small"
                            name="new_password"
                            defaultValue={"password"}
                            type={"password"}
                            required
                            autoComplete='off'
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Box flexGrow={1} />
                        <Box>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: 2,
                                    fontSize: 12,
                                    paddingX: 2,
                                    backgroundColor: "#d65d46",
                                }}
                                type="submit"
                                disabled={isProcess}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}

export default AdminProfile;