import {
    Box, Button, Dialog, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography
} from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import { UserInfo } from '../../context';
import { Close } from '@mui/icons-material';
import AddCardIcon from '@mui/icons-material/AddCard';
import { DropzoneArea } from 'react-mui-dropzone';

const ProprietorList = ({ allowAccount, module }) => {
    const [userList, setUserList] = useState({ data: [], ready: false })
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedDataDialog, setSelectedDataDialog] = useState({ open: false, data: null })
    const [detailsDialog, setDetailsDialog] = useState({ open: false, data: null })
    const [image, setImage] = useState([]);

    const {
        setIsProcess,
        isProcess,
        setMessage,
        setSeverity,
        setOpenSnackbar,
    } = useContext(UserInfo);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getProprietorList = () => {
        var params = { module: "proprietor" }
        axios.get("admin/get/users", { params })
            .then(response => {
                const data = response.data;
                setUserList({ data, ready: true })
            })
            .catch(error => console.log("Error: ", error))
    }

    const handleImageChangeNew = (files) => {
        setImage(files);
    };

    const handleAddPaymentLimit = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("proprietor_id", selectedDataDialog.data && selectedDataDialog.data.userproprietorinfo.user_id)
        formdata.append("branch_limit", selectedDataDialog.data && selectedDataDialog.data.userproprietorinfo.branch_limit)
        formdata.append("image", image[0]);

        setIsProcess(true);
        axios
            .post("admin/update/proprietor/branch_limit", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setTimeout(() => {
                        setIsProcess(false);
                        setMessage("Successfully add branch limit.");
                        setSeverity("success");
                        setSelectedDataDialog({ ...selectedDataDialog, open: false });
                        getProprietorList()
                        setTimeout(() => {
                            setOpenSnackbar(true);
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    }

    useEffect(() => {
        getProprietorList()
    }, [])

    return (
        <Box sx={{ mt: 2 }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align='center'><b>Name</b></TableCell>
                            <TableCell align='center'><b>Contact</b></TableCell>
                            <TableCell align='center'><b>Email</b></TableCell>
                            <TableCell align='center'><b>Branch Limit</b></TableCell>
                            <TableCell align='center'><b>Action</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userList.ready && userList.data.length > 0 ?
                            userList.data.map((data, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <span style={{ cursor: "pointer", color: '#54B4D3' }} onClick={() => setDetailsDialog({ ...detailsDialog, open: true, data })}>
                                            {data.userproprietorinfo.firstname} {data.userproprietorinfo.lastname}
                                        </span>
                                    </TableCell>
                                    <TableCell>{data.userproprietorinfo.contact_no}</TableCell>
                                    <TableCell>{data.email}</TableCell>
                                    <TableCell align='right'>{data.userproprietorinfo.branch_limit}</TableCell>
                                    <TableCell align="center">
                                        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Tooltip title="Verified">
                                                <VerifiedIcon sx={{ color: 'green', marginRight: 1 }} />
                                            </Tooltip>

                                            <Tooltip title="Add Payment">
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedDataDialog({ ...selectedDataDialog, data, open: true })
                                                        // handleAddPaymentLimit(data)
                                                    }}
                                                // disabled={module === 'admin' && parseInt(allowAccount.allow) === 0 ? true : false}
                                                >
                                                    <AddCardIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))
                            : (
                                <TableRow>
                                    <TableCell align='center' colSpan={6} style={{ color: "red" }}>No proprietor found</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={userList.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={detailsDialog.open}
                fullWidth
                maxWidth="sm"
                onClose={() => setDetailsDialog({ ...detailsDialog, open: false, data: null })}
            >
                {detailsDialog.data !== null && (
                    <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box flexGrow={1}>
                                <Typography variant='h6'>
                                    {detailsDialog.data.userproprietorinfo.firstname} {detailsDialog.data.userproprietorinfo.lastname} Information
                                </Typography>
                            </Box>
                            <IconButton onClick={() => setDetailsDialog({ ...detailsDialog, open: false, data: null })}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Email"
                                            fullWidth
                                            size="small"
                                            name="email"
                                            value={detailsDialog.data.email !== null ? detailsDialog.data.email : ""}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Firstname"
                                            fullWidth
                                            size="small"
                                            name="reg_firstname"
                                            value={detailsDialog.data.userproprietorinfo.firstname !== null ? detailsDialog.data.userproprietorinfo.firstname : ""}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Lastname"
                                            fullWidth
                                            size="small"
                                            name="reg_lastname"
                                            value={detailsDialog.data.userproprietorinfo.lastname !== null ? detailsDialog.data.userproprietorinfo.lastname : ""}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box>
                                        <TextField
                                            sx={{
                                                "& .MuiInputLabel-root": {
                                                    color: "#000",
                                                    fontWeight: 700,
                                                },
                                                "& .MuiInput-input": {
                                                    borderRadius: "8px",
                                                    paddingTop: "6px",
                                                    paddingBottom: "6px",
                                                    paddingLeft: "14px",
                                                    paddingRight: "14px",
                                                    fontSize: 14,
                                                },
                                                "& label + .MuiInput-root-emKjRF": {
                                                    marginTop: "18px",
                                                },
                                            }}
                                            InputProps={{
                                                disableunderline: "true",
                                                inputProps: {
                                                    style: {
                                                        color: "#AEAEAE",
                                                        backgroundColor: "#fff",
                                                    },
                                                },
                                                readOnly: true
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Contact No."
                                            fullWidth
                                            size="small"
                                            name="reg_contact_no"
                                            value={detailsDialog.data.userproprietorinfo.contact_no !== null ? detailsDialog.data.userproprietorinfo.contact_no : ""}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </Dialog>

            <Dialog
                open={selectedDataDialog.open}
                fullWidth
                maxWidth="xs"
                onClose={() => setSelectedDataDialog({ ...selectedDataDialog, open: false, data: null })}
            >
                {selectedDataDialog.data !== null && (
                    <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box flexGrow={1}>
                                <Typography variant='h6'>
                                    Add Limit
                                </Typography>
                            </Box>
                            <IconButton onClick={() => setSelectedDataDialog({ ...selectedDataDialog, open: false, data: null })}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <form onSubmit={handleAddPaymentLimit} encType="multipart/form-data">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "6px",
                                                        paddingBottom: "6px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "18px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableunderline: "true",
                                                    inputProps: {
                                                        min: 1,
                                                        max: 99999999999,
                                                        step: 1,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="No. of Limit"
                                                fullWidth
                                                name="new_branch_limit"
                                                required
                                                type="number"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "6px",
                                                        paddingBottom: "6px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "18px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableunderline: "true",
                                                    inputProps: {
                                                        min: 1,
                                                        max: 99999999999,
                                                        step: 1,
                                                    },
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Amount"
                                                fullWidth
                                                name="amount"
                                                required
                                                type="number"
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DropzoneArea
                                            onChange={handleImageChangeNew}
                                            acceptedFiles={["image/jpeg", "image/png"]}
                                            dropzoneText={"Upload proof of payment"}
                                            maxFileSize={2000000}
                                            filesLimit={1}
                                            dropzoneClass={'dropZoneCustom'}
                                            dropzoneParagraphClass={'dropZoneCustomText'}
                                        />
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: "flex",
                                    }}
                                >
                                    <Box flexGrow={1} />
                                    <Box>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                textTransform: "capitalize",
                                                borderRadius: 2,
                                                fontSize: 12,
                                                paddingX: 2,
                                                backgroundColor: "#261138",
                                            }}
                                            type="submit"
                                            disabled={isProcess}
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                )}
            </Dialog>
        </Box >
    )
}

export default ProprietorList;