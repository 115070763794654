import { Box, Button, Dialog, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { UserInfo } from '../../context';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';

const CityList = ({ cityList, getCityList }) => {
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = React.useContext(UserInfo);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [addCityDialog, setAddCityDialog] = React.useState(false);
    const [initialAmount, setInitialAmount] = useState(0)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleNewCity = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);

        setIsProcess(true);
        setAddCityDialog(false);
        axios
            .post("admin/create/new-city", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setMessage("Successfully added city");
                    setSeverity("success");
                    setTimeout(() => {
                        setIsProcess(false);
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            getCityList();
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex" }}>
                <Box flexGrow={1} />
                <Box>
                    <Tooltip title="Add Admin">
                        <IconButton
                            onClick={() => {
                                setAddCityDialog(true);
                            }}
                        >
                            <AddLocationAltIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align='center'><b>City</b></TableCell>
                            <TableCell align='center'><b>Per Item</b></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {cityList.ready && cityList.data.length > 0 ?
                            cityList.data.map((data, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{data.city}</TableCell>
                                    <TableCell align='right'>&#8369;{data.per_item}</TableCell>
                                </TableRow>
                            ))
                            : (
                                <TableRow>
                                    <TableCell align='center' colSpan={6} style={{ color: "red" }}>No city added</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={cityList.data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={addCityDialog}
                onClose={() => setAddCityDialog(false)}
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
                        <Box flexGrow={1}>
                            <Typography style={{ fontWeight: 700 }}>Add City</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => setAddCityDialog(false)}>
                                <Close fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box>
                        <form
                            onSubmit={handleNewCity}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    sx={{
                                        "& .MuiInputLabel-root": {
                                            color: "#000",
                                            // fontFamily: "Futura Md Bt",
                                            fontWeight: 700,
                                        },
                                        "& .MuiInput-input": {
                                            borderRadius: "8px",
                                            paddingTop: "6px",
                                            paddingBottom: "6px",
                                            paddingLeft: "14px",
                                            paddingRight: "14px",
                                            fontSize: 14,
                                        },
                                        "& label + .MuiInput-root-emKjRF": {
                                            marginTop: "18px",
                                        },
                                    }}
                                    InputProps={{
                                        disableunderline: "true",
                                        inputProps: {
                                            style: {
                                                color: "#AEAEAE",
                                                backgroundColor: "#fff",
                                            },
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="City Name"
                                    fullWidth
                                    size="small"
                                    name="city"
                                    required
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                <TextField
                                    sx={{
                                        "& .MuiInputLabel-root": {
                                            color: "#000",
                                            // fontFamily: "Futura Md Bt",
                                            fontWeight: 700,
                                        },
                                        "& .MuiInput-input": {
                                            borderRadius: "8px",
                                            paddingTop: "6px",
                                            paddingBottom: "6px",
                                            paddingLeft: "14px",
                                            paddingRight: "14px",
                                            fontSize: 14,
                                        },
                                        "& label + .MuiInput-root-emKjRF": {
                                            marginTop: "18px",
                                        },
                                    }}
                                    InputProps={{
                                        disableunderline: "true",
                                        inputProps: {
                                            min: 0,
                                            max: 99999999999,
                                            step: 0.01,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Amount Per Item"
                                    fullWidth
                                    size="small"
                                    name="per_item"
                                    required
                                    type="number"
                                    value={initialAmount}
                                    onChange={(e) => setInitialAmount(e.target.value)}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                }}
                            >
                                <Box flexGrow={1} />
                                <Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            paddingX: 2,
                                            backgroundColor: "#d65d46",
                                        }}
                                        type="submit"
                                        disabled={isProcess}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )
}

export default CityList;