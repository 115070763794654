import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import CityList from './CityList';
import FoodBanner from './FoodBanner';
import MerchantBanner from './MerchantBanner';
import VoucherList from './VoucherList';
import VouchersToPay from './VouchersToPay';

const OthersAbout = ({ userRestriction, module }) => {
    const [tabPanel, setTabPanel] = React.useState("voucher");
    const [cityList, setCityList] = useState({ data: [], ready: false })

    // const allowAccount = userRestriction.find(x => parseInt(x.restriction_id) === 1);
    // const allowPayment = userRestriction.find(x => parseInt(x.restriction_id) === 2);
    const allowVoucher = userRestriction.find(x => parseInt(x.restriction_id) === 3);

    const handleChangeMethod = (event, newValue) => {
        setTabPanel(newValue);
    };

    const getCityList = () => {
        axios.get("admin/get/cities")
            .then(response => {
                const data = response.data;
                setCityList({ data, ready: true })
            })
            .catch(error => console.log("Error: ", error))
    }

    useEffect(() => {
        getCityList()
    }, [])

    return (
        <>
            <TabContext value={tabPanel}>
                <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Box>
                        <TabList
                            onChange={handleChangeMethod}
                            aria-label="tabs"
                            TabIndicatorProps={{ style: { background: "#1AE294" } }}
                        >
                            <Tab
                                className="customtabbutton"
                                label="Vouchers"
                                value="voucher"
                            />

                            <Tab
                                className="customtabbutton"
                                label="Vouchers To Pay"
                                value="voucher-to-pay"
                            />

                            <Tab
                                className="customtabbutton"
                                label="City List"
                                value="city-list"
                            />

                            <Tab
                                className="customtabbutton"
                                label="Merchant Banner"
                                value="merchant-banner"
                            />

                            <Tab
                                className="customtabbutton"
                                label="Food Banner"
                                value="food-banner"
                            />
                        </TabList>
                    </Box>

                    <TabPanel value="voucher" className="tabpanelzeropadding">
                        <VoucherList
                            allowVoucher={allowVoucher}
                            module={module}
                        />
                    </TabPanel>

                    <TabPanel value="voucher-to-pay" className="tabpanelzeropadding">
                        <VouchersToPay module={module} />
                    </TabPanel>

                    <TabPanel value="city-list" className="tabpanelzeropadding">
                        <CityList cityList={cityList} getCityList={() => getCityList()} />
                    </TabPanel>

                    <TabPanel value="merchant-banner" className="tabpanelzeropadding">
                        <MerchantBanner />
                    </TabPanel>

                    <TabPanel value="food-banner" className="tabpanelzeropadding">
                        <FoodBanner />
                    </TabPanel>
                </Box>
            </TabContext>
        </>
    )
}

export default OthersAbout;