import {
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    Button
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CustomerInfo, UserInfo } from "../../context";
import { useNavigate } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";


const CustomerOrderDetails = ({ selectedOrder, close }) => {
    const [orders, setOrder] = useState({ data: [], ready: false });
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar, userData } =
        useContext(UserInfo);
    const { userLocation, cartOrder, checkoutOrder, reRunCart, reRunCheckout } = useContext(CustomerInfo)
    const [deleteAll, setDeleteAll] = useState(false);
    const navigate = useNavigate()
    const [locationDialog, setLocationDialog] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [qtyDialog, setQtyDialog] = useState(false);
    const [quantityOrder, setQuantityOrder] = useState(1);

    const getOrderDetails = useCallback(() => {
        var params = {
            transaction_id: selectedOrder.trnxid,
            is_viewed: parseInt(selectedOrder.is_viewed) === 0 ? "new" : "old",
        };
        axios
            .get("merchant/get/spec-order", { params })
            .then((response) => {
                const data = response.data;
                setOrder({ data, ready: true });
            })
            .catch((error) => {
                console.log("error: ", error);
            });
    }, [selectedOrder]);

    const handleAddtoCart = () => {
        var formdata = new FormData();
        formdata.append("customer_id", userData.data.id);
        formdata.append("merchant_id", selectedData.merchant_id);
        formdata.append("product_id", selectedData.id);
        formdata.append("sku", selectedData.sku);
        formdata.append("product", selectedData.product);
        formdata.append("description", selectedData.description);
        formdata.append("category", selectedData.category);
        formdata.append("price", parseFloat(selectedData.price));
        formdata.append("quantity", quantityOrder);
        setIsProcess(true);
        setQtyDialog(false);
        axios
            .post("customer/create/order-to-cart", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setTimeout(() => {
                        setIsProcess(false);
                        setMessage("Successfully added to cart.");
                        setSeverity("success");
                        setTimeout(() => {
                            setOpenSnackbar(true);
                            reRunCart();
                            setSelectedData(null);
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    const handleDeleteAllProduct = () => {
        var formdata = new FormData();
        formdata.append("customer_id", userData.data.id);
        formdata.append("merchant_id", selectedData.merchant_id);
        setIsProcess(true);
        setDeleteAll(false);
        axios
            .post("customer/remove/all-products", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setTimeout(() => {
                        setIsProcess(false);
                        setMessage(
                            "Successfully remove all products from previous merchant."
                        );
                        setSeverity("success");
                        setTimeout(() => {
                            setOpenSnackbar(true);
                            reRunCart();
                            reRunCheckout();
                            setSelectedData(null);
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    };

    useEffect(() => {
        getOrderDetails();
    }, [getOrderDetails]);

    return (
        <>
            {orders.ready && (
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ display: "flex" }}>
                        <Box flexGrow={1}>
                            <Typography style={{ fontWeight: 700 }}>Order Details</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => close()}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <span style={{ fontWeight: 700 }}>Qty</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span style={{ fontWeight: 700 }}>Order</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span style={{ fontWeight: 700 }}>Amount</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span style={{ fontWeight: 700 }}>Action</span>
                                </TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.data.length > 0 ? (
                                orders.data.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="right">{data.quantity}</TableCell>
                                        <TableCell align="left">
                                            {data.product}
                                            <br />
                                            {data.description !== null || data.description !== 'null' ? data.description : ""}
                                        </TableCell>
                                        <TableCell align="right">&#8369;{parseFloat(data.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                sx={{
                                                    color: "#fff",
                                                    fontWeight: 700,
                                                    fontSize: 12,
                                                    backgroundColor: "#50C878",
                                                    borderRadius: 1,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    padding: 1
                                                }}
                                                onClick={() => {
                                                    if (
                                                        userLocation.data.latitude === undefined &&
                                                        userLocation.data.longitude === undefined
                                                    ) {
                                                        setLocationDialog(true);
                                                    } else {
                                                        setSelectedData(data);
                                                        if (cartOrder.data.length > 0) {
                                                            if (
                                                                data.merchant_id !==
                                                                cartOrder.data[0].merchant_id
                                                            ) {
                                                                setDeleteAll(true);
                                                            } else {
                                                                setQtyDialog(true);
                                                            }
                                                        }
                                                        if (checkoutOrder.data.length > 0) {
                                                            if (
                                                                data.merchant_id !==
                                                                checkoutOrder.data[0].merchant_id
                                                            ) {
                                                                setDeleteAll(true);
                                                            } else {
                                                                setQtyDialog(true);
                                                            }
                                                        } else {
                                                            setQtyDialog(true);
                                                        }
                                                    }
                                                }}
                                            >
                                                REORDER
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">No order found!</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            )}

            <Dialog
                open={qtyDialog}
                onClose={() => {
                    setQtyDialog(false);
                    setSelectedData(null);
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={{ fontWeight: 700 }}>Order Quantity</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <IconButton
                            sx={{ mr: 4 }}
                            onClick={() => {
                                if (parseInt(quantityOrder) === 1) {
                                    console.log("Can't add to cart with quantity of 0.");
                                } else {
                                    setQuantityOrder(parseInt(quantityOrder) - 1);
                                }
                            }}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                        <span style={{ fontWeight: 700 }}>{quantityOrder}</span>
                        <IconButton
                            sx={{ ml: 4 }}
                            onClick={() => {
                                setQuantityOrder(parseInt(quantityOrder) + 1);
                            }}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: 2,
                                fontSize: 12,
                                paddingX: 2,
                                backgroundColor: "#d65d46",
                                width: "150px",
                            }}
                            onClick={handleAddtoCart}
                            disabled={isProcess}
                        >
                            Add to cart
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={deleteAll}
                onClose={() => {
                    setDeleteAll(false);
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                            Do you want to delete all products in your <br /> previous
                            merchant.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: 2,
                                fontSize: 12,
                                paddingX: 2,
                                backgroundColor: "#d65d46",
                                mr: 1,
                                width: "140px",
                            }}
                            fullWidth
                            onClick={handleDeleteAllProduct}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: 2,
                                fontSize: 12,
                                paddingX: 2,
                                backgroundColor: "#AEAEAE",
                                width: "140px",
                            }}
                            fullWidth
                            onClick={() => setDeleteAll(false)}
                        >
                            No
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={locationDialog}
                onClose={() => {
                    setLocationDialog(false);
                }}
            >
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography sx={{ fontWeight: 700 }}>
                            Set location first to add products on your cart
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                textTransform: "capitalize",
                                borderRadius: 2,
                                fontSize: 12,
                                paddingX: 2,
                                backgroundColor: "#d65d46",
                            }}
                            fullWidth
                            onClick={() => navigate("/profile")}
                        >
                            Go To Profile
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
};

export default CustomerOrderDetails;
