import React, { useContext } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../components/context";

const LoginUsingEmail = ({ close, tab }) => {
  const [openPasswordInvalid, setOpenPasswordInvalid] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [isProcess, setIsProcess] = React.useState(false);
  const { handleRerunUser } = useContext(UserInfo);
  const navigate = useNavigate();

  const handleGetUser = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    setIsProcess(true);
    axios
      .post("email/login", formdata)
      .then((response) => {
        const data = response.data;
        if (data.message === "account-invalid") {
          setErrorMsg("Invalid Credentials!");
          setOpenPasswordInvalid(true);
          setIsProcess(false);
        } else {
          setTimeout(() => {
            setIsProcess(false);
            setTimeout(() => {
              localStorage.setItem("estherToken", data.access_token);
              close();
              handleRerunUser();
              navigate("/");
              navigate(0);
              // if (tab !== null && tab === "landingpage") {
              //   localStorage.setItem("template", "admin_page");
              //   setTimeout(() => {
              //     navigate("/");
              //     navigate(0);
              //   }, 200);
              // }
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          width: "100%",
          height: `calc(100vh - 64px)`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px solid red",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "360px",
              //   border: "1px solid red",
              paddingX: 2,
            }}
          >
            <form onSubmit={handleGetUser}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 4,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "Futura Md Bt", fontWeight: 700 }}
                >
                  Esther
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                      fontFamily: "Futura Md Bt",
                      fontWeight: 700,
                    },
                    "& .MuiInput-input": {
                      borderRadius: "8px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      fontSize: 14,
                    },
                    "& label + .MuiInput-root-emKjRF": {
                      marginTop: "22px",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      style: {
                        color: "#000",
                        backgroundColor: "#f4f5f7",
                        fontWeight: 700,
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Email Address"
                  variant="standard"
                  fullWidth
                  placeholder="Email Address Here"
                  size="small"
                  name="email"
                  required
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "#000",
                      fontFamily: "Futura Md Bt",
                      fontWeight: 700,
                    },
                    "& .MuiInput-input": {
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                      fontSize: 14,
                    },
                    "& label + .MuiInput-root-emKjRF": {
                      marginTop: "22px",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      style: {
                        color: "#000",
                        backgroundColor: "#f4f5f7",
                        fontWeight: 700,
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Password"
                  variant="standard"
                  fullWidth
                  size="small"
                  name="password"
                  type="password"
                  placeholder="•••••••"
                  required
                />
              </Box>
              <Box sx={{ display: "flex", mb: 2 }}>
                <Typography sx={{ fontSize: 12, textDecoration: "underline" }}>
                  Forgot Password?
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Button
                  sx={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    height: 40,
                    backgroundColor: "#261138",
                    borderRadius: 2,
                  }}
                  fullWidth
                  size="small"
                  type="submit"
                  disabled={isProcess}
                >
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Futura Md Bt"}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    Continue
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
                <Button
                  sx={{
                    color: "#000",
                    textTransform: "none",
                    fontWeight: 700,
                    height: 40,
                    //   backgroundColor: "#2264f6",
                    borderRadius: 2,
                  }}
                  fullWidth
                  size="small"
                  onClick={() => {
                    localStorage.removeItem("estherRedirect")
                    close();
                    navigate('/')
                  }}
                >
                  <ArrowBackIos sx={{ fontSize: "12px ", color: "#AEAEAE" }} />
                  <Typography
                    variant="subtitle2"
                    fontFamily={"Futura Md Bt"}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    Back
                  </Typography>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={openPasswordInvalid}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setOpenPasswordInvalid(false);
        }}
      >
        <Alert
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenPasswordInvalid(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProcess}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" size={70} />
          <Typography sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      </Backdrop>
    </Box>
  );
};

export default LoginUsingEmail;
