import React from "react";
import { Route, Routes } from "react-router-dom";
import GoogleCallback from "../components/google/GoogleCallback";
import GuestPage from "../components/guest";
import GenerateQRWLogo from "../components/guest/GenerateQRWLogo";
import GuestAppbar from "../layouts/GuestAppbar";

const GuestRoute = () => {
  return (
    <React.Fragment>
      <GuestAppbar />
      <Routes>
        <Route hidden path="/" element={<GuestPage />} />
        <Route hidden path="/esther/nehemiah/solutions/generate/qr-with-logo" element={<GenerateQRWLogo />} />
        <Route path="/auth/google" element={<GoogleCallback />} />
      </Routes>
    </React.Fragment>
  );
};

export default GuestRoute;
