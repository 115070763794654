import React, { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { ManageAccounts } from "@mui/icons-material";
import { ProprietorInfo, UserInfo } from "../components/context";
import axios from "axios";
import GoogleCallback from "../components/google/GoogleCallback";
import ProprietorPage from "../components/proprietor";
import ProprietorAppbar from "../layouts/ProprietorAppbar";
// import BranchPage from "../components/proprietor/branch";

const ProprietorRoute = ({ module }) => {
    var interval = null;
    const { userData } = useContext(UserInfo);
    const [merchantInfo, setMerchantInfo] = useState({ data: [], ready: false });

    const getMerchantInfo = () => {
        var params = { user_id: userData.data.id };
        axios
            .get("merchant/get/info", { params })
            .then((response) => {
                const data = response.data;
                setMerchantInfo({ data, ready: true });
            })
            .catch((err) => console.log("err", err));
    };

    const routeArray = [
        {
            name: "Profile",
            path: "/profile",
            icon: <ManageAccounts fontSize="small" />,
        },
        {
            name: "Product",
            path: "/product",
            icon: <Inventory2Icon fontSize="small" />,
        },
    ];

    useEffect(() => {
        getMerchantInfo();

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            <ProprietorInfo.Provider
                value={{
                    merchantInfo,
                    setMerchantInfo,
                    reRunInfo: () => getMerchantInfo(),
                }}
            >
                <ProprietorAppbar
                    module={module}
                    route={routeArray}
                />
                <Routes>
                    <Route hidden path="/" element={<Navigate to={"/proprietor"} />} />
                    <Route hidden path="/proprietor" element={<ProprietorPage />} />
                    {/* <Route hidden path="/branch" element={<BranchPage />} /> */}
                    <Route path="/auth/google" element={<GoogleCallback />} />
                </Routes>
            </ProprietorInfo.Provider>
        </React.Fragment>
    );
};

export default ProprietorRoute;
