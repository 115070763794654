import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';

const DeliveryDetails = ({ dr_number, merchant_id }) => {
    const [specDeliveryDetails, setSpecDeliveryDetails] = useState({ data: [], ready: false });

    const getDeliveryNumberDetails = useCallback(() => {
        var params = { merchant_id, dr_number };
        axios
            .get("merchant/get/spec-delivery", { params })
            .then((response) => {
                const data = response.data;
                setSpecDeliveryDetails({ data, ready: true });
            })
            .catch((err) => console.log("error", err));
    }, [merchant_id, dr_number])

    useEffect(() => {
        getDeliveryNumberDetails()

    }, [getDeliveryNumberDetails])
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Product</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>SKU</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Unit</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Qty</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Convert?</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Converted Unit</TableCell>
                            <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Qty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {specDeliveryDetails.ready ? (
                            specDeliveryDetails.data.length > 0 ? (
                                specDeliveryDetails.data.map((data, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{data.product}</TableCell>
                                        <TableCell>{data.sku}</TableCell>
                                        <TableCell>{data.unit}</TableCell>
                                        <TableCell align="right">{data.sub_quantity}</TableCell>
                                        <TableCell>{parseInt(data.conversion) === 1 ? "Yes" : "No"}</TableCell>
                                        <TableCell>{parseInt(data.conversion) === 1 ? `${data.to_unit}(1x${data.to_unit_qty})` : ""}</TableCell>
                                        <TableCell align="right">{data.quantity}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={8} align="center" sx={{ color: "red" }}>No delivery temp added</TableCell>
                                </TableRow>
                            )
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} align="center" sx={{ color: "blue" }}>Loading...</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default DeliveryDetails;