import {
    Box,
    Button,
    Divider,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { UserInfo } from "../../../context";

const VariantForm = ({ handleFetchVariantList, close, selectedVariant }) => {
    const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const [selectCategory, setSelectCategory] = useState("")
    const [selectAmount, setSelectAmount] = useState("")

    const handleNewVariant = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("product_id", selectedVariant && selectedVariant.id)
        setIsProcess(true);
        close();
        axios
            .post("merchant/create/new-variant", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setMessage("Successfully added variant");
                    setSeverity("success");
                    setTimeout(() => {
                        setIsProcess(false);
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            handleFetchVariantList();
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });

    };

    return (
        <>
            <Box sx={{ m: 2 }}>
                <form onSubmit={handleNewVariant} encType="multipart/form-data">
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <Typography sx={{ fontWeight: 700 }}>New variant</Typography>
                    </Box>

                    <Divider />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            mb: 2
                        }}
                    >
                        <TextField
                            label="Description"
                            fullWidth
                            name="description"
                            required
                            multiline
                        />
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <TextField
                            fullWidth
                            select
                            SelectProps={{ native: true }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={selectCategory !== null ? selectCategory : ""}
                            onChange={(e) => setSelectCategory(e.target.value)}
                            label={`Category`}
                            name="category"
                            required
                        >
                            <option value="">-</option>
                            <option value="free">
                                Free
                            </option>
                            <option value="set">
                                Set Amount
                            </option>
                        </TextField>
                    </Box>

                    {selectCategory === 'set' && (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                mb: 2,
                            }}
                        >
                            <TextField
                                InputProps={{
                                    disableunderline: "true",
                                    inputProps: {
                                        min: 1,
                                        max: 99999999999,
                                        step: 0.01
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={`Additional Charge`}
                                fullWidth
                                value={selectAmount !== null ? selectAmount : ""}
                                onChange={(e) => setSelectAmount(e.target.value)}
                                name="additional_cost"
                                required
                                type="number"
                            />
                        </Box>
                    )}

                    <Box
                        sx={{
                            display: "flex",
                        }}
                    >
                        <Box flexGrow={1} />
                        <Box>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "capitalize",
                                    borderRadius: 2,
                                    fontSize: 12,
                                    paddingX: 2,
                                    backgroundColor: "#261138",
                                }}
                                type="submit"
                                disabled={isProcess}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </>
    );
};

export default VariantForm;
