import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Tooltip,
    Dialog,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../../layouts/Container";
import { UserInfo } from "../../context";
import axios from "axios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const MerchantDelivery = () => {
    const { userData, setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } = useContext(UserInfo);
    const [productList, setProductList] = useState({ data: [], ready: false });
    const [deliveryTempList, setDeliveryTempList] = useState({ data: [], ready: false });
    const [productDetail, setProductDetail] = useState({ data: [], ready: false });
    const [selectedConversion, setSelectedConversion] = useState(null)
    const [selectedConversionUnit, setSelectedConversionUnit] = useState(null)
    const [selectedConversionData, setSelectedConversionData] = useState(null)
    const [inputtedQuantity, setInputtedQuantity] = useState(null)
    const [selectedTempData, setSelectedTempData] = useState({ open: false, data: null })
    const [drDate, setDrDate] = useState(new Date())
    const handleFetchSpecProduct = product_id => {
        var params = { product_id };
        setProductDetail({ data: [], ready: false });
        axios
            .get("merchant/get/spec-product-details", { params })
            .then((response) => {
                const data = response.data;
                setProductDetail({ data, ready: true });
            })
            .catch((err) => console.log("error", err));
    }

    const handleAddDeliveryTemp = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("merchant_id", userData.data.id)
        formdata.append("quantity", Boolean(parseInt(selectedConversion)) ?
            selectedConversionData && selectedConversionData.to_unit_qty !== null && inputtedQuantity !== null ?
                selectedConversionData.to_unit_qty * inputtedQuantity
                : 0
            : inputtedQuantity)
        formdata.append("conversion_id", Boolean(parseInt(selectedConversion)) ?
            selectedConversionData && selectedConversionData.id !== null ?
                selectedConversionData.id
                : ""
            : "")
        formdata.append("to_unit_qty", Boolean(parseInt(selectedConversion)) ?
            selectedConversionData && selectedConversionData.to_unit_qty !== null ?
                selectedConversionData.to_unit_qty
                : ""
            : "")
        formdata.append("to_unit", Boolean(parseInt(selectedConversion)) ?
            selectedConversionData && selectedConversionData.to_unit !== null ?
                selectedConversionData.to_unit
                : ""
            : "")

        setIsProcess(true);
        axios
            .post("merchant/create/product-delivery-temp", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setMessage("Successfully added new delivery temp");
                    setSeverity("success");
                    setTimeout(() => {
                        setIsProcess(false);
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            getProductDeliveryTemp();
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    }

    const handleRemoveProductTemp = () => {
        var formdata = new FormData()
        formdata.append("id", selectedTempData.data && selectedTempData.data.id)
        setIsProcess(true);
        axios
            .post("merchant/delete/product-delivery-temp", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setTimeout(() => {
                        setIsProcess(false);
                        setMessage("Successfully remove the temp product.");
                        setSeverity("success");
                        setSelectedTempData({ ...selectedTempData, open: false, data: null });
                        getProductDeliveryTemp()
                        setTimeout(() => {
                            setOpenSnackbar(true);
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    }

    const handleAddNewDelivery = (e) => {
        e.persist();
        e.preventDefault();
        var formdata = new FormData(e.target);
        formdata.append("merchant_id", userData.data.id)
        formdata.append("dr_date", moment(drDate).format())

        setIsProcess(true);
        axios
            .post("merchant/create/product-delivery", formdata)
            .then((response) => {
                const data = response.data;
                if (data === "success") {
                    setMessage("Successfully added new delivery");
                    setSeverity("success");
                    setTimeout(() => {
                        setIsProcess(false);
                        setOpenSnackbar(true);
                        setTimeout(() => {
                            getProductDeliveryTemp();
                        }, 500);
                    }, 1000);
                }
            })
            .catch((error) => {
                setIsProcess(false);
                console.log("error", error);
            });
    }

    const getProductList = () => {
        var params = { merchant_id: userData.data.id, status: "merchant" };
        axios
            .get("merchant/get/product-list", { params })
            .then((response) => {
                const data = response.data;
                setProductList({ data, ready: true });
            })
            .catch((err) => console.log("error", err));
    };

    const getProductDeliveryTemp = () => {
        var params = { merchant_id: userData.data.id };
        axios
            .get("merchant/get/product-delivery-temp-list", { params })
            .then((response) => {
                const data = response.data;
                setDeliveryTempList({ data, ready: true });
            })
            .catch((err) => console.log("error", err));
    };

    useEffect(() => {
        getProductList();
        getProductDeliveryTemp()
        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Box
                sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        maxWidth: "1200px",
                        paddingX: "8px",
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <form
                                onSubmit={handleAddNewDelivery}
                            >
                                <Box
                                    component={Paper}
                                    elevation={1}
                                    sx={{
                                        padding: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box flexGrow={1}>
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Set Delivery
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                type="submit"
                                                // onClick={() => {
                                                //     localStorage.removeItem("estherToken");
                                                //     setLogoutDialog(false)
                                                //     setTimeout(() => {
                                                //         navigate("/");
                                                //         navigate(0);
                                                //     }, 1000);
                                                // }}
                                                disabled={deliveryTempList.data.length < 1}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            // border: "1px solid red",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                mt: 2,
                                                mb: 1
                                            }}
                                        >
                                            <Box sx={{ display: "flex", width: "100%" }}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <TextField
                                                                sx={{
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#000",
                                                                        fontWeight: 700,
                                                                    },
                                                                    "& .MuiInput-input": {
                                                                        borderRadius: "8px",
                                                                        paddingTop: "6px",
                                                                        paddingBottom: "6px",
                                                                        paddingLeft: "14px",
                                                                        paddingRight: "14px",
                                                                        fontSize: 14,
                                                                    },
                                                                    "& label + .MuiInput-root-emKjRF": {
                                                                        marginTop: "18px",
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    disableunderline: "true",
                                                                    inputProps: {
                                                                        style: {
                                                                            color: "#AEAEAE",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="DR Number"
                                                                fullWidth
                                                                size="small"
                                                                name="dr_number"
                                                                required
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <TextField
                                                                sx={{
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#000",
                                                                        fontWeight: 700,
                                                                    },
                                                                    "& .MuiInput-input": {
                                                                        borderRadius: "8px",
                                                                        paddingTop: "6px",
                                                                        paddingBottom: "6px",
                                                                        paddingLeft: "14px",
                                                                        paddingRight: "14px",
                                                                        fontSize: 14,
                                                                    },
                                                                    "& label + .MuiInput-root-emKjRF": {
                                                                        marginTop: "18px",
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    disableunderline: "true",
                                                                    inputProps: {
                                                                        style: {
                                                                            color: "#AEAEAE",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Receive By"
                                                                fullWidth
                                                                size="small"
                                                                name="receive_by"
                                                                required
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <DatePicker
                                                                label="DR Date"
                                                                openTo="year"
                                                                views={['year', 'month', 'day']}
                                                                value={drDate}
                                                                onChange={(newValue) => {
                                                                    setDrDate(newValue);
                                                                }}
                                                                renderInput={(params) =>
                                                                    <TextField
                                                                        sx={{
                                                                            "& .MuiInputLabel-root": {
                                                                                color: "#000",
                                                                                fontWeight: 700,
                                                                            },
                                                                            "& .MuiInput-input": {
                                                                                borderRadius: "8px",
                                                                                paddingTop: "6px",
                                                                                paddingBottom: "6px",
                                                                                paddingLeft: "14px",
                                                                                paddingRight: "14px",
                                                                                fontSize: 14,
                                                                            },
                                                                            "& label + .MuiInput-root-emKjRF": {
                                                                                marginTop: "18px",
                                                                            },
                                                                        }}
                                                                        InputProps={{
                                                                            disableunderline: "true",
                                                                            inputProps: {
                                                                                style: {
                                                                                    color: "#AEAEAE",
                                                                                    backgroundColor: "#fff",
                                                                                },
                                                                            },
                                                                        }}
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                        {...params}
                                                                        fullWidth
                                                                        size="small"
                                                                        required
                                                                    />
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <TextField
                                                                sx={{
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#000",
                                                                        fontWeight: 700,
                                                                    },
                                                                    "& .MuiInput-input": {
                                                                        borderRadius: "8px",
                                                                        paddingTop: "6px",
                                                                        paddingBottom: "6px",
                                                                        paddingLeft: "14px",
                                                                        paddingRight: "14px",
                                                                        fontSize: 14,
                                                                    },
                                                                    "& label + .MuiInput-root-emKjRF": {
                                                                        marginTop: "18px",
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    disableunderline: "true",
                                                                    inputProps: {
                                                                        style: {
                                                                            color: "#AEAEAE",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Delivered By"
                                                                fullWidth
                                                                size="small"
                                                                name="delivery_by"
                                                                required
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>

                                        <TableContainer>
                                            <Table size="small" sx={{ border: "1px solid #e5e5e5" }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Product</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>SKU</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Unit</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Qty</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Convert?</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Converted Unit</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Qty</TableCell>
                                                        <TableCell align="center" sx={{ fontWeight: "bold" }}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {deliveryTempList.ready ? (
                                                        deliveryTempList.data.length > 0 ? (
                                                            deliveryTempList.data.map((data, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{data.product}</TableCell>
                                                                    <TableCell>{data.sku}</TableCell>
                                                                    <TableCell>{data.unit}</TableCell>
                                                                    <TableCell align="right">{data.sub_quantity}</TableCell>
                                                                    <TableCell>{parseInt(data.conversion) === 1 ? "Yes" : "No"}</TableCell>
                                                                    <TableCell>{parseInt(data.conversion) === 1 ? `${data.to_unit}(1x${data.to_unit_qty})` : ""}</TableCell>
                                                                    <TableCell align="right">{data.quantity}</TableCell>
                                                                    <TableCell align="center">
                                                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                                            <Tooltip title="Delete">
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        setSelectedTempData({ ...selectedTempData, open: true, data });
                                                                                    }}
                                                                                    size="small"
                                                                                >
                                                                                    <DeleteForeverIcon sx={{ color: "red" }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </Box>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow>
                                                                <TableCell colSpan={8} align="center" sx={{ color: "red" }}>No delivery temp added</TableCell>
                                                            </TableRow>
                                                        )
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={8} align="center" sx={{ color: "blue" }}>Loading...</TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box
                                component={Paper}
                                sx={{
                                    padding: 1,
                                    mb: 2
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box flexGrow={1}>
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            Add Product
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        // display: showCategory ? "flex" : "none",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <form
                                        onSubmit={handleAddDeliveryTemp}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                mt: 2,
                                            }}
                                        >
                                            <TextField
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000",
                                                        // fontFamily: "Futura Md Bt",
                                                        fontWeight: 700,
                                                    },
                                                    "& .MuiInput-input": {
                                                        borderRadius: "8px",
                                                        paddingTop: "6px",
                                                        paddingBottom: "6px",
                                                        paddingLeft: "14px",
                                                        paddingRight: "14px",
                                                        fontSize: 14,
                                                    },
                                                    "& label + .MuiInput-root-emKjRF": {
                                                        marginTop: "18px",
                                                    },
                                                }}
                                                InputProps={{
                                                    disableunderline: "true",
                                                    inputProps: {
                                                        style: {
                                                            color: "#AEAEAE",
                                                            backgroundColor: "#fff",
                                                        },
                                                    },
                                                }}
                                                select
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                label="Product"
                                                fullWidth
                                                size="small"
                                                name="product_id"
                                                onChange={(e) => {
                                                    handleFetchSpecProduct(e.target.value)
                                                }}
                                                required
                                            >
                                                <option value=""> - </option>
                                                {productList.data.length > 0 && (
                                                    productList.data.map((data, index) => (
                                                        <option value={data.id} key={index}>
                                                            {data.product}
                                                        </option>
                                                    ))
                                                )}
                                            </TextField>
                                        </Box>

                                        {productDetail.ready && productDetail.data && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <TextField
                                                        sx={{
                                                            "& .MuiInputLabel-root": {
                                                                color: "#000",
                                                                fontWeight: 700,
                                                            },
                                                            "& .MuiInput-input": {
                                                                borderRadius: "8px",
                                                                paddingTop: "6px",
                                                                paddingBottom: "6px",
                                                                paddingLeft: "14px",
                                                                paddingRight: "14px",
                                                                fontSize: 14,
                                                            },
                                                            "& label + .MuiInput-root-emKjRF": {
                                                                marginTop: "18px",
                                                            },
                                                        }}
                                                        InputProps={{
                                                            disableunderline: "true",
                                                            inputProps: {
                                                                style: {
                                                                    color: "#AEAEAE",
                                                                    backgroundColor: "#fff",
                                                                },
                                                            },
                                                            readOnly: true
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        label="Unit"
                                                        fullWidth
                                                        size="small"
                                                        name="unit"
                                                        value={productDetail.data.unit !== null ? productDetail.data.unit : ""}
                                                    />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <TextField
                                                        sx={{
                                                            "& .MuiInputLabel-root": {
                                                                color: "#000",
                                                                // fontFamily: "Futura Md Bt",
                                                                fontWeight: 700,
                                                            },
                                                            "& .MuiInput-input": {
                                                                borderRadius: "8px",
                                                                paddingTop: "6px",
                                                                paddingBottom: "6px",
                                                                paddingLeft: "14px",
                                                                paddingRight: "14px",
                                                                fontSize: 14,
                                                            },
                                                            "& label + .MuiInput-root-emKjRF": {
                                                                marginTop: "18px",
                                                            },
                                                        }}
                                                        InputProps={{
                                                            disableunderline: "true",
                                                            inputProps: {
                                                                style: {
                                                                    color: "#AEAEAE",
                                                                    backgroundColor: "#fff",
                                                                },
                                                            },
                                                        }}
                                                        select
                                                        SelectProps={{ native: true }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        label="Need Convert?"
                                                        fullWidth
                                                        size="small"
                                                        name="conversion"
                                                        value={selectedConversion !== null ? selectedConversion : ""}
                                                        onChange={(e) => {
                                                            setSelectedConversion(e.target.value)
                                                        }}
                                                        required
                                                    >
                                                        <option value=""> - </option>
                                                        <option value={1}> Yes </option>
                                                        <option value={0}> No </option>
                                                    </TextField>
                                                </Box>

                                                {Boolean(parseInt(selectedConversion)) && (
                                                    <>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                mt: 2,
                                                            }}
                                                        >
                                                            <TextField
                                                                sx={{
                                                                    "& .MuiInputLabel-root": {
                                                                        color: "#000",
                                                                        // fontFamily: "Futura Md Bt",
                                                                        fontWeight: 700,
                                                                    },
                                                                    "& .MuiInput-input": {
                                                                        borderRadius: "8px",
                                                                        paddingTop: "6px",
                                                                        paddingBottom: "6px",
                                                                        paddingLeft: "14px",
                                                                        paddingRight: "14px",
                                                                        fontSize: 14,
                                                                    },
                                                                    "& label + .MuiInput-root-emKjRF": {
                                                                        marginTop: "18px",
                                                                    },
                                                                }}
                                                                InputProps={{
                                                                    disableunderline: "true",
                                                                    inputProps: {
                                                                        style: {
                                                                            color: "#AEAEAE",
                                                                            backgroundColor: "#fff",
                                                                        },
                                                                    },
                                                                }}
                                                                select
                                                                SelectProps={{ native: true }}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Config Conversion"
                                                                fullWidth
                                                                size="small"
                                                                // name="to_unit"
                                                                required
                                                                value={selectedConversionUnit !== null ? selectedConversionUnit : ""}
                                                                onChange={(e) => {
                                                                    setSelectedConversionUnit(e.target.value)
                                                                    setSelectedConversionData(productDetail.data && productDetail.data.productconversionconfig.find(x => parseInt(x.id) === parseInt(e.target.value)))
                                                                }}
                                                            >
                                                                <option value=""> - </option>
                                                                {productDetail.data.productconversionconfig.length > 0 && (
                                                                    productDetail.data.productconversionconfig.map((data2, index2) => (
                                                                        <option value={data2.id} key={index2}>
                                                                            {data2.to_unit_qty} {productDetail.data && productDetail.data.unit} in 1 {data2.to_unit}
                                                                        </option>
                                                                    ))
                                                                )}
                                                            </TextField>
                                                        </Box>
                                                    </>
                                                )}

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <TextField
                                                        sx={{
                                                            "& .MuiInputLabel-root": {
                                                                color: "#000",
                                                                // fontFamily: "Futura Md Bt",
                                                                fontWeight: 700,
                                                            },
                                                            "& .MuiInput-input": {
                                                                borderRadius: "8px",
                                                                paddingTop: "6px",
                                                                paddingBottom: "6px",
                                                                paddingLeft: "14px",
                                                                paddingRight: "14px",
                                                                fontSize: 14,
                                                            },
                                                            "& label + .MuiInput-root-emKjRF": {
                                                                marginTop: "18px",
                                                            },
                                                        }}
                                                        InputProps={{
                                                            disableunderline: "true",
                                                            inputProps: {
                                                                min: 0,
                                                                max: 99999999999,
                                                                step: 0.01,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        label={Boolean(parseInt(selectedConversion)) ? `Quantity ${selectedConversionData && selectedConversionData.to_unit !== null ? `of ${selectedConversionData.to_unit}` : ""}` : 'Quantity'}
                                                        fullWidth
                                                        size="small"
                                                        name="sub_quantity"
                                                        required
                                                        type="number"
                                                        value={inputtedQuantity !== null ? inputtedQuantity : ""}
                                                        onChange={(e) => {
                                                            setInputtedQuantity(e.target.value)
                                                        }}
                                                    />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        mt: 2,
                                                    }}
                                                >
                                                    <Box flexGrow={1}>
                                                        <Typography sx={{ fontWeight: "bold" }}>
                                                            Total Quantity : {
                                                                Boolean(parseInt(selectedConversion)) ?
                                                                    selectedConversionData && selectedConversionData.to_unit_qty !== null && inputtedQuantity !== null ?
                                                                        selectedConversionData.to_unit_qty * inputtedQuantity
                                                                        : 0
                                                                    : inputtedQuantity}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            sx={{
                                                                textTransform: "capitalize",
                                                                borderRadius: 2,
                                                                fontSize: 12,
                                                                paddingX: 2,
                                                                backgroundColor: "#d65d46",
                                                            }}
                                                            type="submit"
                                                            disabled={isProcess}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </>
                                        )}
                                    </form>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Dialog
                open={selectedTempData.open}
                onClose={() => setSelectedTempData({ ...selectedTempData, open: false, data: null })}
            // fullWidth
            // maxWidth="xs"
            >
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography>
                                    Are you to remove this product?
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 1 }}>
                                <Box flexGrow={1} />
                                <Box>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            backgroundColor: "#AEAEAE",
                                            width: "40%",
                                            mr: 1
                                        }}
                                        onClick={() => setSelectedTempData({ ...selectedTempData, open: false, data: null })}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: 2,
                                            fontSize: 12,
                                            backgroundColor: "#d65d46",
                                            width: "40%",
                                        }}
                                        onClick={handleRemoveProductTemp}
                                        disabled={isProcess}
                                    >
                                        Confirm
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Container >
    );
};

export default MerchantDelivery;
