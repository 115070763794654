import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { UserInfo } from "../../../../context";

const EditCategory = ({ getCategoryList, close, merchant_id, data }) => {
  const { setIsProcess, isProcess, setMessage, setSeverity, setOpenSnackbar } =
    useContext(UserInfo);

  const handleEditCategory = (e) => {
    e.persist();
    e.preventDefault();
    var formdata = new FormData(e.target);
    formdata.set("id", data && data?.id !== null ? data?.id : "");
    formdata.set("merchant_id", merchant_id);
    setIsProcess(true);
    close();
    axios
      .post("merchant/update/category", formdata)
      .then((response) => {
        const data = response.data;
        if (data === "success") {
          setMessage("Successfully update category");
          setSeverity("success");
          setTimeout(() => {
            setIsProcess(false);
            setOpenSnackbar(true);
            setTimeout(() => {
              getCategoryList();
            }, 500);
          }, 1000);
        }
      })
      .catch((error) => {
        setIsProcess(false);
        console.log("error", error);
      });
  };

  return (
    <Box sx={{ m: 2 }}>
      <form onSubmit={handleEditCategory}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography sx={{ fontWeight: 700 }}>Edit Category</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 2,
          }}
        >
          <TextField
            sx={{
              "& .MuiInputLabel-root": {
                color: "#000",
                // fontFamily: "Futura Md Bt",
                fontWeight: 700,
              },
              "& .MuiInput-input": {
                borderRadius: "8px",
                paddingTop: "6px",
                paddingBottom: "6px",
                paddingLeft: "14px",
                paddingRight: "14px",
                fontSize: 14,
              },
              "& label + .MuiInput-root-emKjRF": {
                marginTop: "18px",
              },
            }}
            InputProps={{
              disableunderline: "true",
              inputProps: {
                style: {
                  color: "#AEAEAE",
                  backgroundColor: "#fff",
                },
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label="Category"
            fullWidth
            size="small"
            name="category"
            defaultValue={data && data?.category !== null ? data?.category : ""}
            required
          />
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box flexGrow={1} />
          <Box>
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "capitalize",
                borderRadius: 2,
                fontSize: 12,
                paddingX: 2,
                backgroundColor: "#261138",
              }}
              type="submit"
              disabled={isProcess}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default EditCategory;
